import ReactEChart from 'echarts-for-react';
// import { useState, useEffect } from 'react';

export function UnpaidARChart ({chartData, label}) {

  let percentCurr = 0;
  let percent31 = 0;
  let percent61 = 0;
  let percentOverdue = 0;
    
    if (chartData) {
      percentCurr = chartData.PERCENT_CURR === 0 || isNaN(chartData.PERCENT_CURR) ? 0 : chartData.PERCENT_CURR.toFixed(2);
      percent31 = isNaN(chartData.PERCENT_31_60) ? 0 : chartData.PERCENT_31_60.toFixed(2);
      percent61 = isNaN(chartData.PERCENT_61_90) ? 0 : chartData.PERCENT_61_90.toFixed(2);
      percentOverdue = isNaN(chartData.PERCENT_OVERDUE) ? 0 : chartData.PERCENT_OVERDUE.toFixed(2);
    } 

    const pieChartOptions = {
        tooltip: {
        trigger: 'item'
        },
        graphic: {
            elements: [{
              type: 'text',
              left: 'center',
              top: 'middle',
              z: 999,
              style: {
                text: label,
                textAlign: 'center',
                fontSize: 26,
              }
            }]
          },
        series: [
        {
            name: 'Unpaid AR',
            type: 'pie',
            radius: ['55%', '95%'],
            avoidLabelOverlap: true,
            startAngle: 180,
            label: {
            show: false,
            position: 'center'
            },
            emphasis: {
                label: {
                    position: 'inside',
                    show: true,
                    fontSize: 20,
                    fontWeight: 'bold',
          
                    formatter(param) {
                        // correct the percentage
                        return param.name + ' (' + param.percent + '%)';
                    },
                    itemStyle: {
                        z: 999,
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                      }
                }
            },
            data: [
            { value: percentCurr, name: 'Current'},
            { value: percent31, name: '31-60 Days', itemStyle: {}},
            { value: percent61, name: '61-90 Days', itemStyle: {}},
            { value: percentOverdue, name: 'Overdue', itemStyle: {}},
            ],
        }
        ]
    }
    return (<ReactEChart option={pieChartOptions} className='upaid-ar-pie-chart' style={{height: '200px', width: '275px'}}/>)  
}

export function ReviewsPie({exceeded, met, improvement, title, width, height, colors}) {

      const pieChartOptions = {
          tooltip: {
              trigger: 'item'
          },
          graphic: {
              elements: [{
                  type: 'text',
                  left: 'center',
                  top: 'middle',
                  z: 999,
                  style: {
                      text: '',
                      textAlign: 'center',
                      fontSize: 26,
                  }
              }]
          },
          series: [
              {
                  name: title,
                  type: 'pie',
                  radius: ['55%', '95%'],
                  avoidLabelOverlap: true,
                  startAngle: 180,
                  label: {
                      show: false,
                      position: 'center'
                  },
                  emphasis: {
                      label: {
                          position: 'inside',
                          show: false,
                          fontSize: 20,
                          fontWeight: 'bold',

                          // formatter(param) {
                          //     // correct the percentage
                          //     return `${param.name} $${param.value.toFixed(2)}`;
                          // },

                          itemStyle: {
                              z: 999,
                              shadowBlur: 10,
                              shadowOffsetX: 0,
                              shadowColor: 'rgba(0, 0, 0, 0.5)'
                          }
                      }
                  },
                  data: [
                      { value: exceeded, name: 'Exceeded Expectations', itemStyle: { color: colors.exceed } },
                      { value: met, name: 'Met Expectations', itemStyle: { color: colors.met } },
                      { value: improvement, name: 'Needs Improvement', itemStyle: { color: colors.improve } },
                  ],
              }
          ]
      }
      return (
          <>
              <h4 className="ring-chart-title">{title}</h4>
              <ReactEChart option={pieChartOptions} style={{ height: height, width: width}} />
          </>
          
      )
  // }
}
