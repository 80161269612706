import { isEqual } from 'lodash';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUser } from "../redux/loginSlice";
import { callMsGraph } from "./graph";
import { InteractionStatus } from "@azure/msal-browser";

export function useLoginAuth(apiRoot, staff_email, graphData, inProgress, instance, authenticated) {
    const dispatch = useDispatch();
    // const [error, setError] = useState(null);

    const bearer = "Mock Bearer Token";

    useEffect(() => {
        if (!graphData && authenticated === "pending" && inProgress === InteractionStatus.None) {
            instance.handleRedirectPromise()
                .then(async (tokenResponse) => {
  
                      return callMsGraph(tokenResponse.accessToken)
                            .then((response) => {
                                console.log("response is", response);
                                return tokenResponse;
                            })
                .then((tokenResponse) => {
                    const { user, domain, ext } = parse_email(tokenResponse);
                    return fetch(`${apiRoot}/staff/me/${user}/${domain}/${ext}`, {
                        method: "GET",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            "x-functions-key": "5cvbp_zFyZsTV9J8dqHD7NSkHHnUxyqR8dM0gSP5RYLfAzFuhVAZHQ==",
                        },
                    });
                })
                .then((res) => {
                    console.log("res is", res);
                    if (!res.ok) {
                        throw new Error("Uh oh! Looks like you are not a registered user with this company");
                    }
                    return res.json();
                })
                .then((data) => {
                    dispatch(setUser({ authenticated: true, ...data, bearer: bearer }));
                })
                .catch((err) => {
                    console.error(err);
                    dispatch(setUser({
                        authenticated: false,
                        message: "Uh oh! Looks like you are not a registered user with this company!",
                    }));
                });
            });
        } else if (authenticated === true) {
            let email = staff_email.toUpperCase().split("@");
            let addressSite = email[1].split(".");
            const user = email[0];
            const domain = addressSite[0];
            const ext = addressSite[1];

            fetch(`${apiRoot}/staff/me/${user}/${domain}/${ext}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "x-functions-key": "5cvbp_zFyZsTV9J8dqHD7NSkHHnUxyqR8dM0gSP5RYLfAzFuhVAZHQ==",
                },
            }).then(res => res.json())
              .then(data => {        
                  if (!isEqual(data.STAFF_EMAIL, staff_email)) {
                      dispatch(setUser({ authenticated: true, ...data, bearer: bearer }));
                  }
              });
        }
    }, [apiRoot, staff_email, graphData, inProgress, instance, authenticated, bearer, dispatch]);
}

function parse_email(tokenResponse) {
    let email = tokenResponse.account.username.toUpperCase();
    if (email.includes("@ABACUSTECHNOLOGIES.COM")) {
        // email = "Tax_Member_48@MagnifyFirm.com".toUpperCase();
        email = "Tax_Senior_Manager_66@MagnifyFirm.com".toUpperCase();
    }
    email = email.split("@");
    let addressSite = email[1].split(".");
    const user = email[0];
    const domain = addressSite[0];
    const ext = addressSite[1];
    return { user, domain, ext };
}