function CreateBubbleTag({color}) {
    return (
    <span className="chart-bubble-tag" style={{background: color}}></span>
    )
}

export default function Cards({metric, cardValue, color}) {
    const value = cardValue ? cardValue : 0;
    let title = ''
    let formattedValue = '';

    switch (metric) {
        case 'UNPAID_INVOICE':
            title = 'Outstanding'
            // target = {targetValue: 2000000, targetTitle: 'Target < $2M', targetOper: 'less'}
            formattedValue = `$${value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`
            break;
        case 'PERCENT_CURR':
            title = '% Current'
            // target = {targetValue: 75, targetTitle: 'Target > 75%', targetOper: 'great'}
            formattedValue = `${value.toFixed(2)}%`
            break;
        case 'PERCENT_31_60':
            title = '% 31-60 days'
            // target = {targetValue: 15, targetTitle: 'Target < 15%', targetOper: 'less'}
            formattedValue = `${value.toFixed(2)}%`
            break;
        case 'PERCENT_61_90':
            title = '% 61-90 days'
            // target = {targetValue: 10, targetTitle: 'Target < 10%', targetOper: 'less'}
            formattedValue = `${value.toFixed(2)}%`
            break;
        case 'PERCENT_OVERDUE':
            title = '% over 90 days'
            // target = {targetValue: 5, targetTitle: 'Target < 5%', targetOper: 'less'}
            formattedValue = `${value.toFixed(2)}%`
            break;
        default:
            title = 'Unknown metric'
    }
        return (
            <article className='metric'>
                <p className="card-title">{title}</p>
                <p className="card-value">{color ? <CreateBubbleTag color={color}/> : ""}{formattedValue}</p>
            </article>
        )
}

export function DataCards(props) {
    return (
        <article className='metric'>
            <p className="card-title">{props.title}</p>
            <p className="card-value">{props.color ? <CreateBubbleTag color={props.color}/> : ""}{props.value}</p>
        </article>
    )
}

export function CalloutCard(props) {
    return (
        <article className='callout'>
            <p className="card-title">{props.title}</p>
            <p className="card-value" style={{marginTop: 20, color: props.color}}>{props.value}</p>
        </article>
    )
}